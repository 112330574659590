import React from 'react'
import Layout from '../../components/Layout'
import BlogRoll from '../../components/Blogpage/BlogRoll'
import Navbar from "../../components/Navbar"

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Navbar />
        <header className="bg-primary bg-blog"></header>
      
         <div className="page-contant container-blog">
            <BlogRoll />
         </div>
      </Layout>
    )
  }
}
